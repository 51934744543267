import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`My Name is Jonas Bandi`}</h1>
    <p><img parentName="p" {...{
        "src": "/jonas_avatar.png",
        "alt": "Jonas Bandi"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote">{`I am passionate about technology and methodologies - always trying to learn,
improve and share knowledge.`}</p>
    </blockquote>
    <p>{`I am working as a freelance developer and as a coach and trainer.`}</p>
    <p>{`My current passion lies in bringing modern frontend development to traditional
enterprise projects.`}</p>
    <p>{`Coming from a Java & .NET background I am now primarily working with JavaScript,
Angular & React.`}<br parentName="p"></br>{`
`}{`In the past years I shared my knowledge in dozens of in-house workshops at
companies all over Switzerland. I am also a lecturer at the University of
Applied Sciences (BFH) in Bern.`}</p>
    <p>{`My Online Presences:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/jonasbandi/"
        }}>{`LinkedIn`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.xing.com/profile/Jonas_Bandi"
        }}>{`Xing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stackoverflow.com/users/32749/jbandi"
        }}>{`Stack Overflow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/jbandi"
        }}>{`Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.jonasbandi.net/blog"
        }}>{`Current Blog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.jonasbandi.net/"
        }}>{`Medium (not active any more)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://blog.jonasbandi.net/"
        }}>{`Old Blog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://old-blog.jonasbandi.net/"
        }}>{`Even Older Blog`}</a></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      